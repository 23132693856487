$(function () {
    Beacon.Home.init();
});


if (!window.Beacon) { Beacon = {} };

// define console so debugging doesnt break ie
if (!window.console) {
    window.console = {
        info: function () { },
        warn: function () { },
        error: function () { }
    }
}


// fake localStorage support for ie7, etc
try {
    if (!window.localStorage) {
        window.localStorage = {
            getItem: function () { },
            removeItem: function () { },
            setItem: function () { }
        };
    }
} catch (e) { }


Beacon.Home = {

    keyCode: {
        RETURN: 13,
        SPACE: 32,
        PAGEUP: 33,
        PAGEDOWN: 34,
        END: 35,
        HOME: 36,
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40,
        SLASH: 191,
        ESCAPE: 27
    },

    data: {},
    // Data object schema:
    //   {
    //     Quickstart: hash[appId: [{ Description, URL }]]
    //     States: { Name, show, Apps[{DisplayName, ID, Name}]
    //     + Apps: hash[appId: {DisplayName, ID, Name}]
    //     + MRU: [{DisplayName, ID, Name}]
    //     + Favorites: [{DisplayName, ID, Name}]
    //   }
    //   + items are added in init()

    _lastAppId: null,

    init: function () {
        var self = this;

        $.getJSON('/api/globalsearch/framework').then(function (d) {
            self.data = d;
            self.buildAppList();
            self.populateStateMenu();
            self.populateAreaMenu(null, self.getInitialSiteFromUrl() || self.getDefaultApp());
        });

        self.showUserInfo();

        self.populateAnnouncements(0);

        $("#loadNewImage").click(self.loadRandomImage);
        $(window).resize(self.onResize);
        self.onResize();

        $("#btnLocal").click(function () {
            $("#tabLocal").show();
            $("#tabSearch").hide();
            $("#btnLocal").parent().addClass('active');
            $("#btnSearch").parent().removeClass('active');
        });

        $("#btnSearch").click(function () {
            $("#tabLocal").hide();
            $("#tabSearch").show();
            $("#btnSearch").parent().addClass('active');
            $("#btnLocal").parent().removeClass('active');
        });

        $("a[data-search]").click(function () {
            var mode = $(this).data("search");
            $("#txtQuery").attr('name', mode);
            switch (mode) {
                case "q":
                    $("#lblQuery").text("Search by name, address, or parcel id");
                    $(this).parents("form").attr('action','/Search');
                    break;
                case "parcelid":
                    $("#lblQuery").text("Search by parcel id");
                    $(this).parents("form").attr('action','/Search/Advanced');
                    break;
                default:
                    $("#lblQuery").text("Search by " + mode);
                    $(this).parents("form").attr('action','/Search/Advanced');
                    break;
            }

        });

        // see if we should show the Search Tab initially:
        if (self.getInitialSearch()) {
            $("#btnSearch").click();
        }

        //listen for the hash change adjust page accordingly
        window.addEventListener('hashchange', function () {
            if (self.getInitialSearch()) {
                $('#btnSearch').click();
            }
        });

        // allow spacebar to activate links that look like buttons
        $("a.btn").not("[role]").keydown(function (e) {
            if (e.keyCode === 32) {
                e.preventDefault();
                e.target.click();
            }
        }).attr('role', 'button');
    },

    getInitialSiteFromUrl: function () {
        var match = RegExp('[?&]site=([^&]*)', 'i').exec(window.location.search);
        if (match) {
            var site = decodeURIComponent(match[1].replace(/\+/g, ' '));
            var siteRow = _.first(_.select(Beacon.Home.data.Apps, { Name: site }));
            if (siteRow) {
                return siteRow.ID;
            }
        }
        return null;
    },

    getInitialSearch: function () {
        var match = RegExp('[&#]search', 'i').exec(window.location.hash);
        return !!match;
    },

    populateAnnouncements: function (appId) {
        var self = this;
        $.getJSON('/api/globalsearch/announcements/' + appId).then(function (d) {
            var app = self.data && self.data.Apps && self.data.Apps[appId];
            var name = app && app.DisplayName;
            $("#announcements")
                .empty()
                .append(Beacon.Home.Templates.templateAnnouncement({ Announcements: d, Name: name }));
        });
    },

    populateStateMenu: function () {
        var self = this;

        for (var a in self.data.States) {
            self.data.States[a].id = self.data.States[a].Name.replace(/[^a-zA-Z]/g, '');
        }

        $("#stateMenu > .advanced-dropdown-content")
            .append(Beacon.Home.Templates.templateStateMenu(self.data))
        ;

        Beacon.AdvancedDropdown.configureAdvancedDropdown('stateMenu', null, function (selectedElement) {
            if ($(selectedElement).data('name') === 'all') {
                $('#areaMenu .state-group,.dropdown-option').removeClass('unselected-state');
            } else {
                $('#areaMenu .state-group:not([data-state="' + $(selectedElement).data('name') + '"])').each(function () {
                    $(this).addClass('unselected-state')
                    $(this).find('.dropdown-option').addClass('unselected-state')
                });
                $('#areaMenu .state-group[data-state="' + $(selectedElement).data('name') + '"]').each(function () {
                    $(this).removeClass('unselected-state');
                    $(this).find('.dropdown-option').removeClass('unselected-state');
                });
            }
        });
    },

    buildAppList: function () {
        var self = this;

        var apps = {};

        for (var stateId in self.data.States) {
            var state = self.data.States[stateId];
            for (var appIdx in state.Apps) {
                var app = state.Apps[appIdx];
                apps[app.ID] = app;
            }
        }

        self.data.Apps = apps;
    },

    populateAreaMenu: function (selectedState, defaultAppId) {
        var self = this;

        // update filter criteria
        for (var a in self.data.States) {
            self.data.States[a].show = !selectedState || (selectedState === self.data.States[a].Name);
            self.data.States[a].id = self.data.States[a].Name.replace(/[^a-zA-Z]/g, '');
        }

        for (var appIdx in self.data.Apps) {
            //sometimes the same state is listed multiple times under different names.
            //This helps to prevent ID collision
            self.data.Apps[appIdx].sanitizedName = self.data.Apps[appIdx].DisplayName.replace(/[^a-zA-Z]/g, '');
        }

        // Build MRU list
        self.computeMruData();
        self.computeFavoriteData();

        self.populateQuickstart(null);

        $("#areaMenu > .advanced-dropdown-content")
            .empty()
            .append(Beacon.Home.Templates.templateAreaMenu(self.data))
            ;

        Beacon.AdvancedDropdown.configureAdvancedDropdown('areaMenu', null, function (selectedElement) {
            if ($(selectedElement).data('appid')) {
                self.populateQuickstart($(selectedElement).data('appid'));
            } else {
                self.populateQuickstart(null);
            }
        });

        if (defaultAppId) {
            $('#areaMenu .advanced-dropdown-button').val(
                $('#areaMenu .dropdown-option[data-appid=' + defaultAppId + ']')
                    .first()
                    .text()
            );
            self.populateQuickstart(defaultAppId);
        }
    },

    computeMruData: function () {
        var self = this;
        var mru = self.getMRUs();
        self.data.MRU = $.map(mru, function (o, idx) {
            return self.data.Apps[o];
        });
        self.data.MRU.reverse();
    },

    computeFavoriteData: function () {
        var self = this;
        var favs = self.getFavorites();
        var favList = $.map(favs, function (o, idx) {
            return self.data.Apps[o];
        });
        self.data.Favorites = _.sortBy(favList, 'DisplayName');
    },

    populateQuickstart: function (appId) {
        var self = this;

        self._lastAppId = appId;

        var defaultAppId = self.getDefaultApp();
        var favorites = self.getFavorites();

        if (appId) {
            self.populateAnnouncements(appId);
            var qs = self.data.Quickstart[appId];
            //console.info(qs);
            $("#quickstartList")
                .empty()
                .append(Beacon.Home.Templates.templateQuickstart({
                    t: qs,
                    ID: appId,
                    isDefault: (defaultAppId == appId),
                    isFavorite: (_.indexOf(favorites, appId) != -1)
                }));
        } else {
            self.populateAnnouncements(0);
            $("#quickstartList")
                .empty();
        }


    },

    LS_Favorites: "home-favorites",
    LS_MRU: "home-mru",
    LS_DefaultApp: "home-default",
    MRU_MAX: 5,

    getFavorites: function () {
        try {
            var s = localStorage[this.LS_Favorites];
            if (!s) {
                return [];
            } else {
                return s.split(",");
            }
        } catch (e) {
            return [];
        }
    },

    saveFavorite: function (appId) {
        var idList = this.getFavorites();
        if (_.indexOf(idList, appId) === -1) {
            idList.push(appId);
            try {
                localStorage[this.LS_Favorites] = idList.join(",");
            } catch (e) { }
        }
        var state = $("#stateMenu").val();
        this.populateAreaMenu(state, appId);
        //this.populateQuickstart(appId);
    },

    removeFavorite: function (appId) {
        var idList = this.getFavorites();
        idList = _.without(idList, appId);
        try {
            localStorage[this.LS_Favorites] = idList.join(",");
        } catch (e) { }
        //this.populateQuickstart(appId);
        var state = $("#stateMenu").val();
        this.populateAreaMenu(state, appId);
    },

    // mru object:  [ ID-oldest, ... , ID-newest ]

    trackMRU: function (appId, el) {
        if (!appId) {
            return;
        }

        var idList = this.getMRUs();
        var idx = _.indexOf(idList, appId);
        if (idx != -1) {
            // remove it, so we can add it to the end
            idList = _.without(idList, appId);
        }
        idList.push(appId);
        idList = idList.slice(this.MRU_MAX * -1);
        try {
            localStorage[this.LS_MRU] = idList.join(",");
        } catch (e) {
        }

        $(el).addClass("picked");

    },

    getMRUs: function () {
        try {
            var s = localStorage[this.LS_MRU];
            if (!s) {
                return [];
            } else {
                return s.split(",");
            }
        } catch (e) {
            return [];
        }
    },

    saveDefaultApp: function (appId) {
        try {
            localStorage[this.LS_DefaultApp] = appId;
        } catch (e) { }

        this.populateQuickstart(appId);
    },

    clearDefaultApp: function () {
        try {
            localStorage[this.LS_DefaultApp] = null;
            this.populateQuickstart(this._lastAppId);
        } catch (e) { }
    },

    getDefaultApp: function () {
        try {
            return localStorage[this.LS_DefaultApp];
        } catch (e) {
            return null;
        }
    },

    onResize: function () {

        var hero = $("#courthousehero");
        var ctrl = $("#mainControl");

        if (hero.height() === 0) {
            hero.load(function () { $(window).resize(); });
        }

        var x = 180;

        if (hero.width() > 700) {
            var pos = hero.height() - x;
            //console.info(pos);
            ctrl.css({ marginTop: pos * -1, minHeight: pos });
        }
        else {
            ctrl.css({ marginTop: '40px', minHeight: '' });
        }
    },

    loadRandomImage: function () {
        var el = $("#courthousehero");
        var old = el[0].src;
        var src = old;
        while (old.indexOf(src) >= 0) {
            src = "/Home/Heros/c" + (Math.floor(Math.random() * 12) + 1) + ".jpg";
        }
        el[0].src = src;
    },

    logout: function () {
        var self = this;

        $.post('/api/authentication/logout').then(function (d) {
            if (d.ok) {
                self.renderUserInfo(false);
            } else {
                alert("failed!");
            }
        });

    },
    showUserInfo: function () {
        var self = this;

        $.post('/api/authentication/status').then(function (d) {
            self.renderUserInfo(d.authenticated, d.Name);
        });
    },

    renderUserInfo: function (authenticated, name) {
        var self = this;

        if (authenticated) {
            $("#username").text(name);
            $(".user-logged-out").hide();
            $(".user-logged-in").show();
        } else {
            $("#username").text('');
            $(".user-logged-in").hide();
            $(".user-logged-out").show();
        }

    },

    CLASS_NAME: "Beacon.Home"

};


